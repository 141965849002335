import request from '@/utils/request'

// 故障类型 分页
export function faultTypePage(query) {
  return request({
    url: '/wisdom/wisdom-fault-type/page',
    method: 'get',
    params: query
  })
}

// 故障类型 详情
export function faultTypeInfo(query) {
  return request({
    url: '/wisdom/wisdom-fault-type/detail',
    method: 'get',
    params: query
  })
}

// 故障类型 新增
export function faultTypeAdd(data) {
  return request({
    url: '/wisdom/wisdom-fault-type/add',
    method: 'post',
    data
  })
}

// 故障类型 编辑
export function faultTypeEdit(data) {
  return request({
    url: '/wisdom/wisdom-fault-type/edit',
    method: 'post',
    data
  })
}

// 故障类型 删除
export function faultTypeDel(data) {
  return request({
    url: '/wisdom/wisdom-fault-type/delete',
    method: 'post',
    data
  })
}

// 故障记录 分页
export function faultPage(query) {
  return request({
    url: '/wisdom/wisdom-fault/page',
    method: 'get',
    params: query
  })
}

// 故障记录 详情
export function faultInfo(query) {
  return request({
    url: '/wisdom/wisdom-fault/detail',
    method: 'get',
    params: query
  })
}

// 故障记录 处理
export function faultAdd(data) {
  return request({
    url: '/wisdom/wisdom-fault/add',
    method: 'post',
    data
  })
}
